"use client"
import React, { useEffect, useState } from "react";
import cookie from "js-cookie";

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consentCookie = cookie.get("cookieConsent");

    if (!consentCookie) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    setShowBanner(false);
    cookie.set("cookieConsent", "accepted", { expires: 365 });
  };

  const handleReject = () => {
    setShowBanner(false);
    cookie.set("cookieConsent", "rejected", { expires: 365 });
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div  className="    rounded-lg bg-gray-700 text-white" style={{
      position: "fixed",
      bottom: "10px",
     
      padding: "5px",
      width: "100%",
    
      }}>
       <p>This website uses cookies to improve your browsing experience.</p>
       <p>Please click Accept to continue.</p>
       <button className="" onClick={handleAccept}>Accept</button>
       <button onClick={handleReject}>Reject</button>
    </div>
  );
};

export default CookieConsentBanner;